import logo from 'assets/logo.svg';
import { useAppContext } from 'context/app-context';
import { motion } from 'framer-motion';
import { Head, Image } from './styles';

export const Header = () => {
  const { speedTestRunning, userDetails, forceTest } = useAppContext();

  const variants = {
    rotate: { rotate: [0, 360], transition: { repeat: Infinity, duration: 1.5 } },
    stop: { rotate: 0 },
    bounce1: { y: [0, -4, 0], transition: { repeat: Infinity, duration: 1 } },
    bounce2: { y: [0, -4, 0], transition: { repeat: Infinity, duration: 1, delay: 0.2 } },
    bounce3: { y: [0, -4, 0], transition: { repeat: Infinity, duration: 1, delay: 0.3 } },
  };

  const ipAddress = userDetails?.ip || 'Unknown';
  let currentProvider = userDetails?.currentProvider || 'your old provider';
  if (currentProvider === 'Vorboss Limited') {
    currentProvider = 'business';
  }

  const message =
    !forceTest && userDetails?.isVorbossIP ? (
      "You're already part of London’s elite business fibre network!"
    ) : (
      <>If you have to ask, ‘Is my {currentProvider} internet fast enough?’, it isn’t.</>
    );

  return (
    <Head>
      <Image
        src={logo}
        alt="logo"
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      />
      <motion.h1
        style={{ margin: '0 2rem 1rem', fontSize: '1.5vh', fontWeight: 500 }}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Your IP is: <b>{ipAddress}</b>
      </motion.h1>
      <motion.h2
        style={{ margin: '0 2rem 1rem', fontSize: '2vh', fontWeight: 500, lineHeight: '2.5vh' }}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        {message}
      </motion.h2>
      {speedTestRunning ? (
        <motion.h2
          style={{ margin: '0 2rem 2rem', fontSize: '2vh', fontWeight: 500 }}
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <>
            Checking your speed
            <motion.div variants={variants} style={{ display: 'inline-block' }} animate="bounce1">
              .
            </motion.div>
            <motion.div variants={variants} style={{ display: 'inline-block' }} animate="bounce2">
              .
            </motion.div>
            <motion.div variants={variants} style={{ display: 'inline-block' }} animate="bounce3">
              .
            </motion.div>
          </>
        </motion.h2>
      ) : null}
    </Head>
  );
};

export default Header;
