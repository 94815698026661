import styled from 'styled-components';

export const StyledNavBar = styled.div`
  background: #1a1a1a;
  height: 55px;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  margin: 10px;
  height: 24px;
`;