import { LocalStateValues } from 'helpers/constants';
import { getLocalStorage } from 'helpers/utils';
import { createContext, useContext } from 'react';
import { AppContextInterface } from 'types/context';

export const defaultContext: AppContextInterface = {
  userDetails: null,
  speedTest: getLocalStorage(LocalStateValues.speedTest),
  speedTestRunning: false,
  resultsModalOpen: false,
  forceTest: false,
  updateAppState: () => undefined,
};

export const AppContext = createContext<AppContextInterface>(defaultContext);

export const useAppContext = (): AppContextInterface => useContext(AppContext);
