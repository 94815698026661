import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import vorbossAnimationVideo from 'assets/545abed5-ca439c77.mp4';
import { Header } from 'components/header';
import ModalContent, { customStyles } from 'components/modal';
import { ShareModalContent } from 'components/modal/share-details';
import { CloseIconContainer } from 'components/modal/styles';
import NavBar from 'components/navbar';
import SpeedLineBar from 'components/speed-line-bar';
import { REACT_APP_CHECK_API_ENDPOINT } from 'config';
import { useAppContext } from 'context/app-context';
import { motion } from 'framer-motion';
import useFetch from 'hooks/use-fetch';
import { useEffect, useRef } from 'react';
import { AppContextInterface } from 'types/context';
import { Container, SpeedLineContainer, VorbossVideo } from './styles';

const Home = () => {
  const matches = useMediaQuery('(max-width:600px)');
  const { updateAppState, resultsModalOpen, speedTest, userDetails, speedTestRunning, forceTest } = useAppContext();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { data, loading } = useFetch<AppContextInterface['userDetails']>(REACT_APP_CHECK_API_ENDPOINT);
  if (data && userDetails?.ip !== data?.ip) {
    updateAppState({ userDetails: data });
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('forceTest')) {
      updateAppState({ forceTest: true });
    }
  }, []);

  const closeModal = () => {
    updateAppState({ resultsModalOpen: false });
  };

  useEffect(() => {
    if (speedTestRunning && videoRef.current) {
      videoRef.current.play();
    } else if (!speedTestRunning && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  });

  return loading || !userDetails ? null : (
    <>
      <Container
        style={{
          filter: resultsModalOpen ? 'blur(5px)' : 'none',
          pointerEvents: resultsModalOpen ? 'none' : 'auto',
        }}
      >
        <CloseIconContainer onClick={closeModal} onKeyDown={closeModal} role="button" tabIndex={0}>
          <CloseIcon fontSize="large" />
        </CloseIconContainer>
        <NavBar />
        <VorbossVideo ref={videoRef} preload="" playsInline muted autoPlay={speedTestRunning} loop>
          <source id="herovsource" src={vorbossAnimationVideo} type="video/mp4" />
        </VorbossVideo>
        <Header />
        <SpeedLineContainer style={{ width: '80%' }}>
          <SpeedLineBar />
        </SpeedLineContainer>

        {!matches ? (
          <motion.div>
            <Modal
              sx={{ inset: 'none !important' }}
              open={resultsModalOpen}
              onClose={closeModal}
              style={customStyles}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              {!userDetails?.isVorbossIP || forceTest ? (
                <ModalContent speedStatus={speedTest} userDetails={userDetails} closeModal={closeModal} />
              ) : (
                <ShareModalContent closeModal={closeModal} />
              )}
            </Modal>
          </motion.div>
        ) : null}
      </Container>
      {matches ? (
        <div
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1900',
            visibility: resultsModalOpen ? 'visible' : 'hidden',
            transition: 'all 0.2s ease',
            paddingBottom: '10vh',
          }}
        >
          {!userDetails?.isVorbossIP || forceTest ? (
            <ModalContent speedStatus={speedTest} userDetails={userDetails} closeModal={closeModal} />
          ) : (
            <ShareModalContent closeModal={closeModal} />
          )}
        </div>
      ) : null}
    </>
  );
};
export default Home;
