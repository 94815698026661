import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CopyIcon from '@mui/icons-material/CopyAll';

import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { EmailShareIcon } from 'assets/icons/EmailShareIcon';
import { WhatsappIcon } from 'assets/icons/WhatsAppIcon';
import logo from 'assets/logo.svg';
import { Image } from 'components/header/styles';
import { motion } from 'framer-motion';
import { shareDetailsTextNonVorbossCustomer, shareDetailsTextVorbossCustomer } from 'helpers/constants';
import { createTrackingLink, sanitizeShareLink } from 'helpers/utils';
import { useState } from 'react';
import { AppContextInterface } from 'types/context';
import { LocalStateInterface } from 'types/storage';
import { CloseIconContainer, ModalText, ModelContentContainer, ShareContainer, ShareTextField } from './styles';

export const customStyles = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  backgroundColor: 'transparent',
  transform: 'translate(-50%,-50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  padding: 0,
  border: 'none',
};

const shareTo = (shareLink: string) => {
  window.location.href = shareLink;
};

const copyToClipboard = () => {
  // Copy the text inside the text field
  navigator.clipboard.writeText(sanitizeShareLink(shareDetailsTextNonVorbossCustomer));
};

export const EmailShareIcosn = () => (
  <a
    target="_blank"
    rel="noreferrer"
    href="mailto:?body=Does%2010Gbps%20seem%20fast%3F%20%0A%0ATime%20to%20reconsider%20your%20provider.%20%0A%0AVorboss%20is%2010-100Gbps%20dedicated%20business%20internet%20from%20%C2%A3650%20per%20month.%20Learn%20more%3A%20https%3A%2F%2Fvorboss.com%2Fsign-up"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-mail"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#d5966a"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="3" y="5" width="18" height="14" rx="2" />
      <polyline points="3 7 12 13 21 7" />
    </svg>
  </a>
);

export const ShareButtons = ({ isVorbossCustomer }: { isVorbossCustomer: boolean }) => {
  const [copied, setCopied] = useState(false);
  return (
    <ShareContainer>
      <Button
        onClick={() => {
          copyToClipboard();
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        }}
        sx={{
          width: '33%',
          padding: '0.5rem 1rem',
        }}
        variant="outlined"
        endIcon={copied ? null : <CopyIcon />}
      >
        {copied ? 'Copied!' : 'Copy Text'}
      </Button>
      <Button
        onClick={() =>
          shareTo(
            `https://api.whatsapp.com/send?text=${encodeURIComponent(
              isVorbossCustomer
                ? sanitizeShareLink(shareDetailsTextVorbossCustomer)
                : sanitizeShareLink(shareDetailsTextNonVorbossCustomer),
            )}`,
          )
        }
        sx={{
          width: '33%',
          padding: '0.5rem 1rem',
        }}
        variant="outlined"
        endIcon={<WhatsappIcon />}
      >
        Share via WhatsApp
      </Button>
      <Button
        onClick={() =>
          shareTo(
            `mailto:?body=${encodeURIComponent(
              isVorbossCustomer
                ? sanitizeShareLink(shareDetailsTextVorbossCustomer)
                : sanitizeShareLink(shareDetailsTextNonVorbossCustomer),
            )}`,
          )
        }
        sx={{
          width: '33%',
          padding: '0.5rem 1rem',
        }}
        variant="outlined"
        endIcon={<EmailShareIcon />}
      >
        Share via Email
      </Button>
    </ShareContainer>
  );
};

export const ModalContent = ({
  speedStatus,
  userDetails,
  closeModal,
}: {
  closeModal: () => void;
  speedStatus: LocalStateInterface['speedTest'];
  userDetails: AppContextInterface['userDetails'];
}) => {
  createTrackingLink({
    destination: 'https://vorboss.com/sign-up',
    param: 'utm_isp',
    value: encodeURI(userDetails?.currentProvider || ''),
  });

  return (
    <ModelContentContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <CloseIconContainer onClick={closeModal} onKeyDown={closeModal} role="button" tabIndex={0}>
        <CloseIcon fontSize="large" />
      </CloseIconContainer>
      <Image src={logo} alt="logo" />
      <h1 style={{ margin: 0, marginTop: '0' }}>Results</h1>
      <ModalText>
        <h4>
          The results speak for themselves. <br />
          No business internet has a better CV.
        </h4>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '6vh 0',
          }}
        >
          <div>
            <h5 style={{ paddingBottom: '0.4rem' }}>Your speed</h5>
            <h6 style={{ fontWeight: '400' }}>
              {speedStatus}
              <span>Mbps</span>
            </h6>
          </div>
          <motion.div
            style={{ border: '1px solid rgba(0,0,0,0.5)', margin: '0 2rem', height: '60px' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
          <div>
            <h5 style={{ paddingBottom: '0.4rem' }}>Vorboss speed</h5>
            <h6 style={{}}>
              10<span>Gbps</span>
            </h6>
          </div>
        </div>
        <h4 style={{ fontSize: '1.5vh', marginTop: '0.25rem' }}>
          Vorboss is the next generation of business internet. Find out what your business could do with 10Gbps.
        </h4>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            flexDirection: 'column',
          }}
        >
          <Button
            component="a"
            sx={{
              width: '100%',
              padding: '0.5rem 1rem',
              boxShadow: 'none',
              textTransform: 'inherit',
            }}
            variant="contained"
            href={createTrackingLink({
              destination: 'https://vorboss.com/sign-up',
              param: 'utm_isp',
              value: encodeURI(userDetails?.currentProvider || ''),
            })}
            endIcon={<ArrowRightAltIcon />}
          >
            Talk to an expert
          </Button>
          <h4 style={{ fontSize: '2vh', marginTop: '1rem', marginBottom: 0 }}>
            Out of your hands? Share your results with your IT team.
          </h4>
          <ShareTextField>
            <p
              style={{
                margin: '0',
                fontWeight: 600,
                fontSize: '0.9rem',
                padding: '0 0 1rem',
                textAlign: 'left',
              }}
            >
              Share message
            </p>
            <span
              style={{ fontSize: '1rem', lineHeight: '1.5rem', display: 'inline-block' }}
              dangerouslySetInnerHTML={{ __html: shareDetailsTextNonVorbossCustomer }}
            />
          </ShareTextField>
          <ShareButtons isVorbossCustomer={false} />
        </div>
      </ModalText>
    </ModelContentContainer>
  );
};
export default ModalContent;
