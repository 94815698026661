import CloseIcon from '@mui/icons-material/Close';
import logo from 'assets/logo.svg';
import { Image } from 'components/header/styles';
import { shareDetailsTextVorbossCustomer } from 'helpers/constants';
import { ShareButtons } from '.';
import { CloseIconContainer, ModalText, ModelContentContainer, ShareTextField } from './styles';

export const ShareModalContent = ({ closeModal }: { closeModal: () => void }) => (
  <ModelContentContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
    <CloseIconContainer onClick={closeModal} onKeyDown={closeModal} role="button" tabIndex={0}>
      <CloseIcon fontSize="large" />
    </CloseIconContainer>
    <Image src={logo} alt="logo" />
    <h1 style={{ margin: 0, marginTop: '0' }}>Results</h1>
    <ModalText>
      <h4>Vorboss is the next generation of business internet.</h4>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '4vh 0',
        }}
      >
        <div
          style={{
            padding: '3vh 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <h5 style={{ paddingBottom: '0.2rem' }}>Vorboss speed</h5>
          <h6 style={{ fontSize: '4.5vh', marginTop: '0.25rem' }}>
            10<span>Gbps</span>
          </h6>
        </div>
      </div>
      <h4 style={{ fontSize: '2vh', marginTop: '0.25rem' }}>Share your results to help someone upgrade to 10Gbps!</h4>
      <ShareTextField>
        <p
          style={{
            margin: '0',
            fontWeight: 600,
            fontSize: '0.9rem',
            padding: '0 0 1rem',
            textAlign: 'left',
          }}
        >
          Share message
        </p>
        <span
          style={{ fontSize: '1rem', lineHeight: '1.5rem', display: 'inline-block' }}
          dangerouslySetInnerHTML={{ __html: shareDetailsTextVorbossCustomer }}
        />
      </ShareTextField>
      <ShareButtons isVorbossCustomer />
    </ModalText>
  </ModelContentContainer>
);

export default ShareModalContent;
