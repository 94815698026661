import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  width: 300px;
  height: 50px;
  border: none;
  outline: none;
  margin: 2rem 0;
  font-size: 1.5rem;
  border-radius: 8px;
  padding: 0.25rem 1rem;
`;

export const SpeedLineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShareButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: block;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  -o-object-fit: fill;
  object-fit: fill;
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const VorbossVideo = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-image: url(https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg);
  background-position: 0 0;
  background-size: auto;
  -o-object-fit: cover;
  object-fit: cover;
  background-image: none !important;
  opacity: 0.3;
`;
