export const LocalStateValues = {
  speedTest: 'speedTest',
};

export const PageRoutes = {
  home: '/',
  speedTest: '/speed-test',
  exampleRoute: '/example-route',
};

export const shareDetailsTextNonVorbossCustomer = `
Does 10Gbps seem fast? Perhaps it's time we reconsider our provider.
<br /><br />
Vorboss provides 10-100Gbps dedicated business internet from £650 per month.
<br />
Learn more: https://vorboss.com/sign-up
`;

export const shareDetailsTextVorbossCustomer = `
Does 10Gbps seem fast? Time to reconsider your provider.
<br /><br />
Vorboss provides 10-100Gbps dedicated business internet from £650 per month.
<br />
Learn more: https://vorboss.com/sign-up
`;
