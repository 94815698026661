import * as Sentry from '@sentry/react';

export const setLocalStorage = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error: any) {
    throw new Error(`Failed to set local storage: ${error.message}`);
  }
};

export const getLocalStorage = (key: string) => {
  try {
    if (key) {
      const state = window.localStorage.getItem(key);
      return JSON.parse(state ?? 'null');
    }
    return null;
  } catch (error: any) {
    throw new Error(`Failed to get "${key}" from local storage: ${error.message}`);
  }
};

export const createTrackingLink = ({
  destination,
  param,
  value,
}: {
  destination: string;
  param: string;
  value: string | undefined;
}) => {
  try {
    if (!value) return destination;

    let searchParams = '';
    const search = new URLSearchParams(window.location.search);

    const invalidParams = ['forceTest'];

    search.forEach((paramValue, paramProperty) => {
      if (invalidParams.includes(paramProperty)) {
        return;
      }

      searchParams = `${searchParams}&${paramProperty}=${paramValue}`;
    });

    return `${destination}?${searchParams}&${param}=${value}`;
  } catch (error) {
    Sentry.captureException(error);
    return destination;
  }
};

export const mbToBits = (speedInMb: number) => {
  try {
    return speedInMb * 1000 * 1000;
  } catch (error) {
    Sentry.captureException(error);
    return speedInMb;
  }
};

export const formatSpeed = (speedInBitsPerSecond: number) => {
  try {
    let formattedSpeed: string;

    switch (true) {
      case speedInBitsPerSecond < 1000:
        formattedSpeed = `${speedInBitsPerSecond}bps`;
        break;
      case speedInBitsPerSecond < 1000 * 1000:
        formattedSpeed = `${(speedInBitsPerSecond / 1000).toFixed(0)}Kbps`;
        break;
      case speedInBitsPerSecond < 1000 * 1000 * 1000:
        formattedSpeed = `${(speedInBitsPerSecond / (1000 * 1000)).toFixed(0)}Mbps`;
        break;
      default:
        formattedSpeed = `${(speedInBitsPerSecond / (1000 * 1000 * 1000)).toFixed(0)}Gbps`;
        break;
    }

    return formattedSpeed;
  } catch (error) {
    Sentry.captureException(error);
    return speedInBitsPerSecond;
  }
};

export const sanitizeShareLink = (inputText: string) => {
  try {
    // Remove HTML tags and replace with a single space
    const textWithoutTags = inputText.replace(/<[^>]+>/g, ' ');

    // Replace consecutive spaces with a single space
    const textWithSingleSpaces = textWithoutTags.replace(/(\S) +/g, '$1 ');

    return textWithSingleSpaces.trim();
  } catch (error) {
    Sentry.captureException(error);
    return inputText;
  }
};
