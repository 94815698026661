import { Button } from '@mui/material';
import { REACT_APP_SPEEDTEST_ENDPOINT } from 'config';
import { useAppContext } from 'context/app-context';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { SpeedTestData } from 'types/speed-test';
import { formatSpeed, mbToBits } from 'helpers/utils';
import { Bar, BarContainer, BarText, BarTextContainer, Container, Spinner } from './styles';

const s = new window.Speedtest();
s.setParameter('url_dl', `${REACT_APP_SPEEDTEST_ENDPOINT}/garbage.php`);
s.setParameter('url_ul', `${REACT_APP_SPEEDTEST_ENDPOINT}/empty.php`);
s.setParameter('url_ping', `${REACT_APP_SPEEDTEST_ENDPOINT}/empty.php`);
s.setParameter('url_getIp', `${REACT_APP_SPEEDTEST_ENDPOINT}/getIP.php`);
s.setParameter('url_telemetry', `${REACT_APP_SPEEDTEST_ENDPOINT}/results/telemetry.php`);

const SpeedLineBar = () => {
  const [speedStatus, setSpeedStatus] = useState(0);
  const [speedHeight, setSpeedHeight] = useState(0);
  const [finished, setFinished] = useState(false);
  const { updateAppState, userDetails, forceTest, speedTestRunning } = useAppContext();
  const VORBOSS_SPEED = 10000;
  const MIN_HEIGHT = 10;

  /**
   * We want to prevent breaking the UI by setting
   * the height past the bounds of its container.
   */
  const calculateHeightFromSpeed = (speed: number) => {
    const MAX_HEIGHT = VORBOSS_SPEED + 1000;

    /**
     * Set this to just above 100 to display the
     * difference without breaking the UI
     */
    if (speed > MAX_HEIGHT) return 105;

    return (speed / VORBOSS_SPEED) * 100;
  };

  s.onupdate = (data: SpeedTestData) => {
    const speed = parseInt(`${data.dlStatus || 0}/`, 10);
    setSpeedHeight(calculateHeightFromSpeed(speed));
    setSpeedStatus(parseInt(speed.toFixed(0), 10));
  };

  s.onend = (aborted: boolean) => {
    if (aborted) {
      setSpeedStatus(speedStatus);
    }
    setFinished(true);
    updateAppState({ speedTestRunning: false, resultsModalOpen: true, speedTest: speedStatus });
  };

  useEffect(() => {
    if (userDetails !== null && (!userDetails?.isVorbossIP || forceTest) && s.getState() !== 3) {
      s.start();
      setTimeout(() => {
        s.abort();
      }, 20000);
      updateAppState({ speedTestRunning: true });
    }
  }, [userDetails?.isVorbossIP]);

  return (
    <Container>
      <BarContainer>
        <Bar
          style={{ color: speedTestRunning ? '#ffffffb3' : '#fff', fontSize: '2vh', padding: '1rem 0' }}
          color="#0062ff"
          height={speedHeight + MIN_HEIGHT}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.2 }}
        >
          {!userDetails?.isVorbossIP || forceTest ? (
            <>
              {formatSpeed(mbToBits(speedStatus))}
              {speedTestRunning && <Spinner lightColor="#ffffffb3" mainColor="#fff" />}
            </>
          ) : (
            '1Gbps'
          )}
        </Bar>
        <Bar
          color="#d495cc"
          height={100}
          style={{ fontSize: '2vh' }}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: '100%' }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5, duration: 0.5 }}>
            10Gbps
          </motion.span>
        </Bar>
      </BarContainer>
      <div>
        <motion.div
          style={{ height: '5px', backgroundColor: '#1a1a1a' }}
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: '100%' }}
          transition={{ duration: 1 }}
        />
        <BarTextContainer>
          <BarText>
            {!forceTest && userDetails?.isVorbossIP ? 'Average connection' : ''}
            {!userDetails?.isVorbossIP || forceTest ? userDetails?.currentProvider || 'Your ISP' : null}
          </BarText>
          <BarText>Vorboss</BarText>
        </BarTextContainer>
      </div>
      {finished && (!userDetails?.isVorbossIP || forceTest) && (
        <Button
          variant="outlined"
          type="button"
          onClick={() => updateAppState({ resultsModalOpen: true })}
          sx={{
            alignSelf: 'center',
            width: '50%',
            margin: '2rem 0 2rem',
            padding: '0.5rem 0',
            textTransform: 'inherit',
          }}
        >
          See results
        </Button>
      )}
      {!forceTest && userDetails?.isVorbossIP && (
        <Button
          variant="outlined"
          type="button"
          onClick={() => updateAppState({ resultsModalOpen: true })}
          sx={{
            alignSelf: 'center',
            width: '50%',
            margin: '2rem 0 2rem',
            padding: '0.5rem 0',
            textTransform: 'inherit',
          }}
        >
          Share details
        </Button>
      )}
    </Container>
  );
};

export default SpeedLineBar;
