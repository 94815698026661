import { useContextState } from 'hooks';
import React from 'react';
import { AppContext, defaultContext } from './app-context';

interface ContextProps {
  children: React.ReactNode;
}

const Context = ({ children }: ContextProps) => {
  const [appContext] = useContextState(defaultContext);

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};

export default Context;
