import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 2;
  margin-top: 2vh;

  @media (max-width: 600px) {
    h2 {
      font-size: 2vh;
    }
  }
`;

const spin = keyframes`
  to { -webkit-transform: rotate(360deg); }
`;

export const Spinner = styled.div<{ lightColor: string; mainColor: string }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 1vh;
  border-radius: 50%;
  border: 3px solid ${({ lightColor }) => lightColor};
  border-top-color: ${({ mainColor }) => mainColor};
  animation: ${spin} 0.5s linear infinite;
`;

export const BarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 35vh;
  align-items: flex-end;
  justify-content: space-around;
`;

export const BarTextContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-around;
`;

export const Bar = styled(motion.div)<{ color: string; height: number }>`
  width: 20%;
  ${({ height }) => height > 0 && `height: ${height}%`};
  min-height: 10%;
  background-color: ${({ color }) => color};
  border: 2px solid ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5vh;
  color: white;
  transition: height 0.5s ease-in-out;

  @media (max-width: 800px) {
    width: 40%;
  }
`;

export const BarText = styled.div`
  width: 45%;
  height: 10%;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5vh;
  color: white;
  margin-top: 1rem;
`;
