import { PageRoutes } from 'helpers/constants';
import Home from 'pages/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from './not-found';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path={PageRoutes.home} element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
