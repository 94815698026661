import * as Sentry from '@sentry/react';
import { VorbossThemeProvider } from '@vorboss/ui-components';
import App from 'pages';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import Context from './context';
import reportWebVitals from './reportWebVitals';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Poppins', sans-serif !important;
     margin : 0; padding : 0;box-sizing: border-box;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(18, 39, 44) !important;
    position: relative;
  }
`;

Sentry.init({
  dsn: 'https://d585d93262ba2efd8cf568d0ad128efa@o1401017.ingest.sentry.io/4505838560739328',
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/check.vorboss\.com/],
    }),
    new Sentry.Replay(),
  ],
  initialScope: {
    tags: {
      component: window.env.REACT_APP_SENTRY_COMPONENT || process.env.REACT_APP_SENTRY_COMPONENT,
      hash: window.env.REACT_APP_SENTRY_HASH || process.env.REACT_APP_SENTRY_HASH,
    },
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <VorbossThemeProvider>
      <Context>
        <App />
      </Context>
    </VorbossThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
