import { useEffect, useState } from 'react';


function useFetch<T>(url: string): { data: T | null, loading: boolean } {
  const [data, setData] = useState< T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(url);
        const json = await response.json() as  T;
        setData(json);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [url]);

  return { data, loading };
}

export default useFetch;