import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Head = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  z-index: 2;
  margin-top: 2vh;
  margin-bottom: 1vh;
  transition: margin 0.5s ease;

  padding: 10px;

  h1 {
    font-size: 2vh;
  }
  h2 {
    font-size: 2vh;
    line-height: 3vh;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 2.25vh !important;
    }

    h2 {
      font-size: 2.5vh;
      line-height: 4vh;
    }
  }
`;

export const Image = styled(motion.img)`
  height: 12vmin;
  pointer-events: none;
`;
