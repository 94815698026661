import logo from 'assets/long_logo.svg';
import { Logo, StyledNavBar } from './styles';

export const NavBar = () => (
  <StyledNavBar>
    <div>
      <Logo src={logo} alt="Vorboss logo" />
    </div>
  </StyledNavBar>
);

export default NavBar;
