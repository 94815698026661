import { LocalStateValues } from "helpers/constants";
import { setLocalStorage } from "helpers/utils";
import { useCallback, useMemo, useState } from "react";
import { LocalStateInterface } from "types";

const refreshLocalState = (state: Partial<LocalStateInterface>) => {
  Object.values(LocalStateValues).forEach((storeKey: string) => {
    const newState = state[storeKey.toString()];

    if (!newState) {
      return;
    }

    setLocalStorage(storeKey, newState);
  });
};


export const useContextState = <T>(defaultContext: T) => {
  const [state, updateState] = useState<T>(defaultContext);

  const updateAppState = useCallback(
    (newState: Partial<T>): void => {
      updateState({ ...state, ...newState, updateAppState });
      refreshLocalState(newState as Partial<LocalStateInterface>);
    },
    [state],
  );

  const valueState = useMemo(() => ({ ...state, updateAppState }), [state, updateAppState]);

  return [valueState];
};

export default useContextState