import { Button } from '@mui/material';
import { PageRoutes } from 'helpers/constants';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
 display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
}

h1, h3 {
  text-align: center;
  margin: 1rem;
}
`;

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <NotFoundContainer>
      <Helmet>
        <title>Vorboss | 404 Not Found</title>
      </Helmet>
      <h1>404 | Oops! No signal.</h1>
      <h3 style={{ marginTop: '0.5rem', fontWeight: '400' }}>
        But don’t worry. We’ll never let you lose your connection.
      </h3>
      <Button sx={{ padding: '0.8rem 2rem', textTransform: 'inherit' }} onClick={() => navigate(PageRoutes.home)}>
        Go back
      </Button>
    </NotFoundContainer>
  );
};

export default NotFound;
