import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ShareTextField = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #edeff2;
  padding: 1rem;
  border-radius: 8px;

  p {
    color: #323232;
  }

  span {
    color: rgba(0, 0, 0, 0.4);
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.2s ease;
`;

export const ModalText = styled.div`
  text-align: left;
  font-size: 1.4em;
  margin: 10px;
  border-radius: 8px;
  width: 80%;
  padding: 1rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    padding: 0;
    margin: 0;
  }

  button {
    margin: 0 auto;
  }

  h4 {
    font-size: 2vh;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 2rem;
    text-align: center;
  }

  h5 {
    font-weight: 400;
    font-size: 1.5vh;
    color: #717880;
  }

  h6 {
    margin: 0;
    font-weight: 600;
    font-size: 3.5vh;
    margin-bottom: 0;
    line-height: 0.85;

    span {
      font-size: 1.5rem;
    }
  }

  p {
    margin-top: 2rem;
    font-size: 2.2vh;
    a {
      color: #0062ff;
    }
  }
`;

export const ModalButton = styled.button`
  font-size: 1.4em;
  background: #b6d7a8;
  width: 100%;
  height: 80px;
  cursor: pointer;
`;

export const ModelContentContainer = styled(motion.div)`
  background-color: white;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: black;
  padding: 1rem 0;
  padding-left: 6px;
  width: 90%;

  @media only screen and (min-width: 600px) {
    width: 1200px;
    margin: 0 2rem;
  }

  @media only screen and (max-width: 600px) {
    overflow-y: scroll;
    width: 100vw;
    height: 100%;
    border-radius: 0 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    padding-top: 40px;

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #d5966a;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;

    button {
      width: 100%;
    }
  }
`;
